import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Icon from '../../images/tree.inline.svg';

const SkillsImg = () => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fixed(width: 350) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={() => {
      return <Icon />;
    }}
  />
);

export default SkillsImg;
