import React from 'react';
import Fade from 'react-reveal/Fade';
import { Row, Container, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import SkillsImg from '../Image/SkillsImg';

const Skills = () => {
  return (
    <section id="skills">
      <Container>
        <Title title="Skills" />
        <Fade bottom duration={700} delay={600} distance="30px">
          <div className="skills-wrapper__image">
            <SkillsImg />
          </div>
        </Fade>
        <Fade bottom duration={700} delay={600} distance="30px">
          <Row xs={1} sm={4} className="skills-languages">
            <Col className="skills-language-item">
              <h3>🇪🇸 Spanish</h3>
              <h3>★★★★★</h3>
            </Col>
            <Col className="skills-language-item">
              <h3>🇬🇧 English</h3>
              <h3>★★★★☆</h3>
            </Col>
            <Col className="skills-language-item">
              <h3>🇮🇹 Italian</h3>
              <h3>★★★★☆</h3>
            </Col>
            <Col className="skills-language-item">
              <h3>🇫🇷 French</h3>
              <h3>★★☆☆☆</h3>
            </Col>
          </Row>
        </Fade>
      </Container>
    </section>
  );
};

export default Skills;
