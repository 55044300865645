import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';
// Import css files for carrousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Training = () => {
  const { trainings } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="training">
      <Container>
        <Title title="Recent Training" />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <Slider {...settings}>
            {trainings.map((training) => {
              const { title, url, type, trainingStatus } = training;
              return (
                <div>
                  <p className="padding" />
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <h2 className="trainingTitle box">{title}</h2>
                  </a>
                  <h3 className="trainingType">{type}</h3>
                  <h3 className="">
                    - <i>{trainingStatus}</i> -
                  </h3>
                  <p className="padding" />
                </div>
              );
            })}
          </Slider>
        </Fade>
      </Container>
    </section>
  );
};

export default Training;
