import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import AwsCertified from '../Image/AwsCertified';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const {
    img,
    paragraphOne1,
    boldParagraphOne,
    paragraphOne2,
    paragraphTwo1,
    boldParagraphTwo,
    paragraphTwo2,
    paragraphThree1,
    boldParagraphThree,
    paragraphThree2,
    paragraphFour,
    topics,
    resume,
  } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={700} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={700} delay={700} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne1}
                  <b>{boldParagraphOne}</b>
                  {paragraphOne2}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo1}
                  <b>{boldParagraphTwo}</b>
                  {paragraphTwo2}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree1}
                  <b>{boldParagraphThree}</b>
                  {paragraphThree2}
                </p>
                <p className="about-wrapper__info-text">{paragraphFour}</p>
                <p className="about-wrapper__info-text">{topics}</p>
                <p className="aws-certified">
                  <AwsCertified alt="aws certified" filename="aws_logo_web.png" />
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
